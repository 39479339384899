<template>
  <div class="play-tab df-center">
    <div class="item flex-1" @click="onRead">
      <zm-icon icon-class="read" class="fs28"></zm-icon>
      <div class="name">跟读练习</div>
    </div>
    <div class="item flex-1" @click="onPlay">
      <zm-icon :icon-class="isPlaying ? 'circlePause' : 'autoPlay'" class="fs28"></zm-icon>
      <div class="name">{{ isPlaying ?  '暂停播放':'自动播放' }}</div>
    </div>
    <div class="item flex-1" @click="onListerner" :class="{ disabled: unitListLength - 1 === unitIndex }">
      <zm-icon icon-class="listerner" class="fs28"></zm-icon>
      <div class="name">听写练习</div>
    </div>
    <!-- <a href="javascript:;" @click="onPause">暂停</a> -->
    <div ref="audioBox" class="audio-box"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'WordFooter',
  components: {},
  props: {
    value: {
      type: Number,
      default: 0
    },
    audioArr: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      myAudio: new Audio(),
      isPlaying: false
    };
  },
  computed: {
    ...mapGetters(['unitIndex', 'unitListLength']),
    playArr: {
      get() {
        return this.audioArr.slice(this.value).reverse();
      },
      set(v) {
        console.log(v);
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    onRead(type) {
      this.$emit('read');
    },
    onListerner() {
      this.$emit('listerner');
    },
    onPlay() {
      if (!this.audioArr.length) return;
      let vm = this;
      if (vm.isPlaying) {
        return this.onPause();
      }
      if (this.value === this.audioArr.length - 1) this.$emit('input', 0);

      //   let reverseAudioArr = this.audioArr.slice(this.value ? this.value - 1 : 0).reverse();
      let reverseAudioArr = this.playArr;
      this.closePlay();
      this.myAudio = new Audio();
      let refAudio = this.myAudio;
      refAudio.removeEventListener('ended', playEndedHandler, false);
      this.isPlaying = true;
      refAudio.preload = true;
      refAudio.controls = true;
      let audioSrc = reverseAudioArr.pop().split('@@');
      refAudio.src = audioSrc[0]; // 每次读数组最后一个元素
      this.$emit('input', +audioSrc[1]);
      refAudio.addEventListener('ended', playEndedHandler, false);
      setTimeout(() => {
        audioSrc && refAudio.play();
      }, 150);
      console.log(vm.$refs.audioBox);
      vm.$refs.audioBox.appendChild(refAudio);
      refAudio.loop = false; // 禁止循环，否则无法触发ended事件
      function playEndedHandler() {
        let audioSrc = reverseAudioArr.pop().split('@@');
        refAudio.src = audioSrc[0]; // 每次读数组最后一个元素
        vm.$emit('input', +audioSrc[1]);
        setTimeout(() => {
          audioSrc && refAudio.play();
        }, 150);
        console.log(reverseAudioArr.length);
        if (!reverseAudioArr.length) {
          refAudio.removeEventListener('ended', playEndedHandler, false); // 只有一个元素时解除绑定
          vm.isPlaying = false;
        }
      }
      this.$emit('play');
    },
    closePlay() {
      let audioBox = this.$refs.audioBox;
      audioBox.childNodes.length && audioBox.removeChild(audioBox.childNodes[0]);
      this.isPlaying = false;
    },
    onPause() {
      this.myAudio.pause();
      this.isPlaying = false;
    },
    onRepeat() {
      this.isPlaying = false;
      this.onPlay();
    }
  }
};
</script>
<style lang="scss" scoped>
.play-tab {
  background: darken($--color-primary, 5%);
  padding: 0.2rem 0.1rem;
  .circle-play {
    font-size: 1rem;
  }
  .item {
    text-align: center;
    font-size: 0.24rem;
    color: #fff;
    &.disabled {
      color: lighten($--color-primary, 10%);
    }
  }
  .audio-box {
    display: none;
  }
}
</style>
