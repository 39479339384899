<template>
  <zmAppPage :show-footer="true" :showHeaderLeft="true" :show-header-right="true" title="单词学习" :scrollTop="scrollTop">
    <div class="pb20 bg-white">
      <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
        <van-cell v-for="(item, index) in list" :key="index" ref="item" @click="handleItem(item, index)">
          <div class="icon-box" slot="icon">
            <van-icon class="mine-icon text-primary" name="share" v-if="currentIndex === index" />
          </div>
          <div slot="title" class="df-center">
            <span class="mr10">{{ index }}:</span>
            <span class="flex-one mr10">{{ item.text }}</span>
            <span class="flex-one">{{ item.textCn }}</span>
          </div>
        </van-cell>
      </van-list>
    </div>
    <FooterBtn slot="footer" v-model="currentIndex" @listerner="toListerner" @read="toRead" :audio-arr="currentPageAudio" @input="changeIndex"></FooterBtn>
  </zmAppPage>
</template>

<script>
import FooterBtn from './components/FooterBtn';
import * as path from '@/router/routes/routePath';
import bookApi from '@/api/src/book';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'UnitWord',
  components: {FooterBtn},
  data() {
    return {
      loading: false,
      finished: false,
      isPlay: false,
      sec: 0,
      secTemp: 0,
      list: [],
      currentIndex: 0,
      scrollTop: 0
    };
  },
  computed: {
    ...mapGetters(['currentBook', 'unitIndex', 'unitList']),
    unitId() {
      return this.$route.query.unitId;
    },
    currentPageAudio() {
      let res = [];
      if (this.list && this.list.length) {
        res = this.list.map((item,index) => `${item.audio}@@${index}`);
      }
      return  res;
    }
  },
  watch: {},
  created() {
    setTimeout(() => {
      this.scrollTop = 20;
    },2000);
  },
  methods: {
    ...mapActions('app', ['setUnitIndex', 'setUnitList','setWordList']),
    async onLoad() {
      const res = await bookApi.getUnitWord({
        pageNo: 0,
        pageSize: 99,
        unitId: this.unitId
      });
      this.list = [...this.list,...res.data];
      this.setWordList(this.list);
      this.currenIndex = 0;
      // this.setUnitList(res.data);
      this.loading = false;
      if (res.data.length < res.pageSize) this.finished = true;
    },
    handleItem(item, index) {
      if (!this.isPlay) {
        this.currentIndex = index;
        this.sec = Math.ceil(item.trackLength / 1000) + '"';
        this.secTemp = Math.ceil(item.trackLength / 1000) + '"';
        this.play(item.audio);
      }
    },
    cutSec(num) {
      this.sec = num + '"';
      num -= 1;
      let timer = null;
      if (num > -1) {
        let timer = setTimeout(() => {
          this.cutSec(num);
        }, 1000);
      } else {
        this.sec = this.secTemp;
        this.isPlay = false;
        clearTimeout(timer);
        timer = null;
      }
    },
    play(audioSrc) {
      let audio = new Audio(),
        numArr = this.sec.split('"'),
        vm = this;
      audio.autoplay = true;
      this.isPlay = true;
      this.$nextTick(function() {
        vm.cutSec(+numArr[0]);
        audio.src = audioSrc;
      });
    },
    toListerner() {
      this.$router.push({
            path: path.PATH_LISTERNER_WORD
        });
    },
    toRead() {
        this.$router.push({
            path: path.PATH_READ_WORD
        });
    },
    changeIndex(e) {
      this.scrollTop = this.$refs.item[e].offsetTop + this.$refs.item[e].clientHeight;
    }
  }
};
</script>
<style lang="scss" scoped>
.icon-box {
  width: 0.44rem;
  height: 0.44rem;
  vertical-align: middle;
}
</style>
